import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Breadcrumb  from "../components/breadcrumb"
import styled from 'styled-components'
import Image from "gatsby-image"
import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"
import Link from '../components/link'

export const query = graphql`
query {
  playStoreIcon: file(relativePath: { eq: "assets/images/app/playStoreIcon.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  appStoreIcon: file(relativePath: { eq: "assets/images/app/appStoreIcon.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  appCode: file(relativePath: { eq: "assets/images/sms_code_app_icon.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

const Box = styled.div`
 margin-top: 3rem;
`

let Store = ({ title, link, image }) => (
  <Col>
    <Box>
      <h4 className="text-center">{title}</h4>
      <Link to={link} className="link">
        <StoreImage fluid={image} imgStyle={{ objectFit: "contain" }} />
      </Link>
    </Box>
  </Col>
);

const StoreImage = styled(Image)`
max-width: 200px;
max-height: 200px;
display: block;
margin-left: auto;
margin-right: auto;
`

export default function SmsApp(props) {
  
  const { siteTitle } = useSiteMetadata()

  const appStoreIcon = props.data.appStoreIcon.childImageSharp.fluid;
  const playStoreIcon = props.data.playStoreIcon.childImageSharp.fluid;
  const appCode = props.data.appCode.childImageSharp.fluid;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Instalar Portal Puntano 4.0 | {siteTitle}</title>
      </Helmet>
      <Container>
        <h1 className="text-center m-4">Instalar Portal Puntano 4.0</h1>
        <Row className="justify-content-center">
          <p></p>
        </Row>
        <Row className="justify-content-center">
          <Store
            title={"Descarga la aplicación para Android"}
            link={
              "https://play.google.com/store/apps/details?id=com.gpsl.st.portalpuntanomultiplataforma"
            }
            image={playStoreIcon}
          />
          <Store
            title={"Descarga la aplicación para iOS"}
            link={
              "https://apps.apple.com/ar/app/portal-puntano-4-0/id1478459285"
            }
            image={appStoreIcon}
          />
        </Row>
        <Row className="justify-content-center">

          <Store title={"Despues de instalar"} link={"/smsCodeApp"} image={appCode} />
        </Row>
      </Container>

      <Breadcrumb
        pageContext={props.pageContext}
        label="Instalar Portal Puntano 4.0"
      />
    </Layout>
  );
}
